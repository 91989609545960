export default defineI18nLocale(async (locale) => {
    return {
        "Your temporary store name is currently {name}.": "Seu nome temporário da loja é atualmente {name}.",
        "Host": "Gerente",
        "Owner": "Catálogo",
        "Staff": "Vendas",
        "HOST": "Gerente",
        "OWNER": "Catálogo",
        "STAFF": "Vendas",
        "Your store is currently {status}.": "Sua loja está atualmente {status}.",
        "Your email {email} has been successfully verified. You can now go back to the App.": "Seu e-mail {email} foi verificado com sucesso. Você pode voltar ao aplicativo agora.",
        // onboard
        "We just need some basic info to get your store setup. You’ll be able to edit this later.": "Só precisamos de algumas informações básicas para configurar sua loja. Você poderá editar isso depois.",
        // Team
        "Team Members": "Membros da Equipe",
        "Invite a member": "Convidar um membro",
        "Add others to your account": "Adicionar outras pessoas à sua conta",
        "Add people who help manage your store. They will be able to see everything you can see.": "Adicione pessoas que ajudam a gerenciar sua loja. Elas poderão ver tudo que você pode ver.",
        "Send invite": "Enviar convite",
        // team
        "Invited at": "Convidado em",
        "Resend invite": "Reenviar convite",
        "Cancel invite": "Cancelar convite",
        "Remove user": "Remover usuário",
        // currency
        "Preferred currency": "Moeda preferida",
        "Currency": "Moeda",
        "Select a currency": "Selecione uma moeda",
        // language
        "Preferred language": "Idioma preferido",
        "Select a language": "Selecione um idioma",
        "Language": "Idioma",
        // passkey
        "Use biometrics": "Usar biometria",
        "Log in with your Face ID": "Faça login com seu Face ID",
        "Log in with your fingerprint": "Faça login com sua impressão digital",
        // auth/passkey
        "Next time, log in with face or fingerprint": "Na próxima vez, faça login com rosto ou impressão digital",
        "Using a passkey to log in is as easy as unclocking your device.": "Usar uma chave de acesso para fazer login é tão fácil quanto desbloquear seu dispositivo.",
        "Yes, use fingerprint": "Sim, usar impressão digital",
        "Skip for now": "Pular por enquanto",
        "Biometric authentication is now set up": "A autenticação biométrica está agora configurada",
        "Turn in off anytime form settings.": "Desligue a qualquer momento nas configurações.",
        // invite
        "Invite": "Convidar",
        "The link to your invitation has expired or is not valid. Please contact your referrer for a reinvite.": "O link para seu convite expirou ou não é válido. Por favor, contate seu referenciador para um novo convite.",
        "You have been invited to join {team_name} by {invited_by}": "Você foi convidado a se juntar a {team_name} por {invited_by}",
        "Thank you for registering {name}": "Obrigado por se registrar, {name}",
        "We\'re glad that you\'re with us": "Estamos felizes que você esteja conosco",
        "Accept invitation": "Aceitar convite",
        "Your account email address is": "Seu endereço de e-mail da conta é",
        // passwords
        "Change password": "Alterar senha",
        "Last updated": "Última atualização",
        "Change your account password": "Altere a senha da sua conta",
        "Current password": "Senha atual",
        "New password": "Nova senha",
        "Confirm password": "Confirmar senha",
        "Update password": "Atualizar senha",
        // change-password
        "Set a new password": "Defina uma nova senha",
        "Reset password": "Redefinir senha",
        "Your password was reset successfully": "Sua senha foi redefinida com sucesso",
        "Now you can go and login with your new password.": "Agora você pode fazer login com sua nova senha.",
        "Password reset links expire after an hour for security purposes, please request another password reset link below to continue": "Os links para redefinição de senha expiram após uma hora por motivos de segurança, solicite outro link para redefinir a senha abaixo para continuar",
        // forgot-password
        "Reset your password": "Redefinir sua senha",
        "Enter your e-mail and we will send you a link to change your password": "Digite seu e-mail e enviaremos um link para alterar sua senha",
        "Send password reset link": "Enviar link de redefinição de senha",
        "Reset email sent": "E-mail de redefinição enviado",
        "Please check your inbox and make your that it\'s you or not and reset your password": "Por favor, verifique sua caixa de entrada e confirme se é você ou não e redefina sua senha",
        "Resent email": "E-mail reenviado",
        "Did you receive this email? Check your inbox or": "Você recebeu este e-mail? Verifique sua caixa de entrada ou",
        "Already have an account?": "Já tem uma conta?",
        // email
        "Forgot password?": "Esqueceu a senha?",
        "Email address": "Endereço de e-mail",
        "Confirm email": "Confirmar e-mail",
        "Confirm your email address": "Confirme seu endereço de e-mail",
        "We sent a confirmation email to": "Enviamos um e-mail de confirmação para",
        "Check your email and click on the confirmation link to continue.": "Verifique seu e-mail e clique no link de confirmação para continuar.",
        "Resend email": "Reenviar e-mail",
        // confirm-email
        "Email is verified!": "E-mail verificado!",
        "This link has expired": "Este link expirou",
        "Sorry! We couldn\'t verify your email, please request another link below to continue.": "Desculpe! Não conseguimos verificar seu e-mail, solicite outro link abaixo para continuar.",
        // phone
        "Phone number": "Número de telefone",
        "Add phone number": "Adicionar número de telefone",
        "Confirm phone": "Confirmar telefone",
        "Enter verification code": "Digite o código de verificação",
        "Add your phone number": "Adicione seu número de telefone",
        "Please enter the six-digit code we just sent to your number": "Por favor, digite o código de seis dígitos que acabamos de enviar para o seu número",
        "Please enter the five-digit code we just sent to your number": "Por favor, digite o código de cinco dígitos que acabamos de enviar para o seu número",
        "Resend code": "Reenviar código",
        "Code": "Código",
        "Use a different phone number": "Usar um número de telefone diferente",
        "Wrong phone number?": "Número de telefone errado?",
        // subscription-item
        "per month": "por mês",
        "Cancel subscription": "Cancelar assinatura",
        // subscriptions
        "Billing and Subscriptions": "Faturamento e Assinaturas",
        "Get more information about managing your apps subscriptions, biling history, and more.": "Obtenha mais informações sobre como gerenciar suas assinaturas de aplicativos, histórico de faturamento e mais.",
        // address
        "Address": "Endereço",
        "Country": "País",
        "Street address": "Endereço da rua",
        "Street number": "Número da rua",
        "Apt, suite. (Optional)": "Apt, suíte. (Opcional)",
        "City": "Cidade",
        "State": "Estado",
        "Postalcode": "Código postal",
        // name
        "These details could be publicly available.": "Esses detalhes podem estar disponíveis publicamente.",
        // privacy
        "Delete account": "Excluir conta",
        "Permanently delete your account and all of your data.": "Excluir permanentemente sua conta e todos os seus dados.",
        "Are you sure you want to delete your account?": "Tem certeza de que deseja excluir sua conta?",
        "When you delete your account, all of your data will be deleted and can\'t be retrieved. Deleting your account cannot be undone.": "Quando você excluir sua conta, todos os seus dados serão excluídos e não poderão ser recuperados. A exclusão da sua conta não pode ser desfeita.",
        "No, keep my account": "Não, manter minha conta",
        "Yes, delete my account": "Sim, excluir minha conta",
        // store/name
        "Name your store": "Nome sua loja",
        "Your store name is currently {name}.": "O nome da sua loja é atualmente {name}.",
        "Name store": "Nome da loja",
        "Store Name": "Nome da loja",
        // store/status
        "Your store is currently": "Sua loja está atualmente",
        // domain
        "Change to a new domain": "Mudar para um novo domínio",
        "Your domain name is currently": "Seu nome de domínio é atualmente",
        "Change domain": "Mudar domínio",
        "Store details": "Detalhes da loja",
        "Domain Name": "Nome de domínio",
        "Our suggested use is": "Nosso uso sugerido é",
        // auth/login
        "Sign in to your account": "Faça login na sua conta",
        "Welcome back you\'ve been missed": "Bem-vindo de volta, você foi muito bem-vindo",
        "Continue with Email": "Continuar com E-mail",
        "Continue with Phone Number": "Continuar com Número de Telefone",
        "Continue with Bio": "Continuar com Biometria",
        "Continue with Google": "Continuar com Google",
        "Continue with Facebook": "Continuar com Facebook",
        "Don\'t have an account yet?": "Ainda não tem uma conta?",
        // auth/signup
        "Hello, register now!": "Olá, registre-se agora!",
        "Let\'s create your account here": "Vamos criar sua conta aqui",
        // apps
        "We play nice with your favorite software.": "Nos damos bem com seu software favorito.",
        "Back to App Directory": "Voltar ao Diretório de Aplicativos",
        "Get Started": "Começar",
        // apps/subscribe
        "Select a plan": "Selecione um plano",
        "Plan": "Plano",
        "billed yearly at": "faturado anualmente em",
        "Subscribe": "Assinar",
        // success
        "Login Successful": "Login bem-sucedido",
        "You have successfully signed into your account. You can close this window and continue using the app.": "Você fez login na sua conta com sucesso. Você pode fechar esta janela e continuar usando o aplicativo.",
        // Properties
        "Properties": "Propriedades",
        "Property": "Propriedade",
        "properties": "propriedades",
        "property": "propriedade",
        "DRAFT": "Rascunho",
        "PENDING": "Pendente",
        "PUBLISHED": "Publicado",
        "ARCHIVED": "Arquivado",
        "APARTMENT": "Apartamento",
        "HOUSE": "Casa",
        "LOFT": "Sótão",
        "BOAT": "Barco",
        "CAMPER_RV": "Trailer de acampamento",
        "CONDOMINIUM": "Condomínio",
        "CHALET": "Chalé",
        "BED_BREAKFAST": "Cama e café da manhã",
        "VILLA": "Villa",
        "TENT": "Tenda",
        "CABIN": "Cabine",
        "TOWNHOUSE": "Moradia",
        "BUNGALOW": "Bungalow",
        "HUT": "Cabana",
        "Sort by": "Ordenar por",
        "Property Information": "Informações sobre a propriedade",
        "Property status": "Status da propriedade",
        "Property type": "Tipo da propriedade",
        "Property name": "Nome da propriedade",
        "Property address": "Endereço da propriedade",
        "Property photos": "Fotos da propriedade",
        "Country of location": "País de localização",
        "Photo captions": "Legendas das fotos",
        "Manual property registration": "Cadastro manual de propriedade",
        "Choose a name for your property": "Escolha um nome para sua propriedade",
        "Property created successfully": "Propriedade criada com sucesso",
        "An error occurred while listing properties": "Ocorreu um erro ao listar as propriedades",
        "An error occurred while loading the property": "Ocorreu um erro ao carregar a propriedade",
        "An error occurred while creating the property": "Ocorreu um erro ao criar a propriedade",
        // Generic
        "Dashboard": "Painel",
        "Booking": "Reserva",
        "Calendar": "Calendário",
        "Messages": "Mensagens",
        "Account": "Conta",
        "Preferences": "Preferências",
        "Personal info": "Informações pessoais",
        "Information": "Informação",
        "Details": "Detalhes",
        "Support": "Suporte",
        "About": "Sobre",
        "Pricing": "Preços",
        "Profile": "Perfil",
        "Billing": "Faturamento",
        "Settings": "Configurações",
        "Platform": "Plataforma",
        "App Marketplace": "Mercado de Aplicativos",
        "Sign up": "Registrar-se",
        "Sign in": "Entrar",
        "Today": "Hoje",
        "Privacy": "Privacidade",
        "Security": "Segurança",
        "Store": "Loja",
        "Free": "Gratuito",
        "Menu": "Menu",
        "Home": "Home",
        "Bookings": "Reservas",
        "Status": "Status",
        "Logout": "Sair",
        "Listings": "Listagens",
        "Need help?": "Precisa de ajuda?",
        "Visit our Help Center.": "Visite nossa Central de Ajuda.",
        "Terms of Service": "Termos de Serviço",
        "Privacy Policy": "Política de Privacidade",
        "Cookie Policy": "Política de Cookies",
        "Version": "Versão",
        "You": "Você",
        "Save": "Salvar",
        "Edit": "Editar",
        "Cancel": "Cancelar",
        "Change": "Mudar",
        "Continue": "Continuar",
        "Configure": "Configurar",
        "Verify": "Verificar",
        "Apply": "Aplicar",
        "Login": "Entrar",
        "Enable": "Ativar",
        "Disable": "Desativar",
        "Accept": "Aceitar",
        "Decline": "Recusar",
        "Search": "Pesquisar",
        "Filter": "Filtro",
        "Filters": "Filtros",
        "Opps": "Oops",
        "Name": "Nome",
        "Full Name": "Nome completo",
        "Password": "Senha",
        "E-mail": "E-mail",
        "Phone": "Telefone",
        "Time zone": "Fuso horário",
        "Clear filters": "Limpar filtros",
        "Refine your search": "Refine sua busca",
        "Back": "Voltar",
        "Next": "Próximo",
        "Welcome": "Bem-vindo",
        "Welcome to Ermith!": "Bem-vindo ao Ermith!",
        "Not defined": "Não definido",
        "Go to the App": "Ir para o App",
        "OTHER": "Outro",
        "Select your country": "Selecione seu país",
        // Forms
        "Minimum 5 characters": "Mínimo 5 caracteres",
        "Name is a required field": "Nome é um campo obrigatório",
        "Country is a required field": "País é um campo obrigatório",
        "60 character limit": "Limite de 60 caracteres",
    }
})
